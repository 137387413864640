import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardAverageCPMHolder = styled.div`
  .cartAudience {
    position: static;
    z-index: 996;
    height: auto;
    width: 88%;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    float: right;
    margin: 0px 10px 0px 0px;

    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .cartAudienceHidden {
    position: fixed;
    z-index: 2;
    top: calc(50% - 25px);
    right: -150px;
    height: 45px;
    width: 150px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    @media only screen and (min-width: 768px) {
      top: calc(85px + 127px + 140px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(85px + 77px + 120px);
    }
  }
  .impacts-container{
    display: flex;
    align-items: center;
  }
  .text-title {
    text-align: left;
    font-size: 20px;
    opacity: 0px;
    margin-bottom: 10px;
  }
  .content-card {
    display: flex;
  }
  .text-data{
    font-weight: 600;
    font-size: 18px;
  }
  .col-card{
    display: content;
    margin-right: 10px;
    margin-left: 10px;
  }
  .col-card-margin{
    margin-top: 10px;
    margin-left: 0px;
  }
  .line-divider{
    width: 100%;
    min-width: 80%;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .buttonAudienceDetails {
    background: none;
    margin-left: 10px;
    height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
  .cartAudience {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
  }
  .cartAudienceHidden {
    transition: all 0.3s cubic-bezier(1, 0.355, 0.045, 0.645)
  }
  .cartAudienceHidden .ant-row {
    visibility: hidden;
  }
  .cartAudience .anticon.aimIcon svg, .cartAudienceVideoUpload .anticon.aimIcon svg{
    width: 19px;
    height: 19px;
  }
  .cardAudienceInfo {
    font-size: 13px;
    margin-right: 5px
  }
  .cardAudienceTypeInfo {
    font-size: 12px
  }
  .cardAudienceIconInfo {
    margin: 10px 5px 5px 0px;
  }
  .cardAudienceIconInfoInfo {
    margin: 10px 5px 5px 5px;
  }
  .carAudienceAimIcon {
    margin-right: 5px;
    margin-left: 5px
  }
    .infoDate {
    margin-top: 10px;
    margin-bottom: 1px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .content-card {
    display: flex;
    text-align: justify;
  }
  .cardGeneral {
    padding-left: 10px;
    padding-bottom: 5px;
    line-height: 25px;
    font-size: 16px;
    text-align: center;
    color: aliceblue;
  }
  .brandClientNameContainer {
    background-color: white;
    border-radius: 8px;
    margin-right: 10px;
    
    @media only screen and (max-width: 576px) {
      margin-right: 10px;
      padding: 8px;
    }
  }
  .brandClientName {
    margin: 0;
    color: ${Colors.primary};
    font-weight: 500;
    font-size: 14px;
  }
  .infoDate {
    margin-top: 10px;
    margin-bottom: 1px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .seeDetailsBtn {
    background-color: ${Colors.primary};
    color: white;
    padding: 4px 15px;
    font-size: 9px;
    height: auto;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    min-width: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
    &:hover {
      background-color: ${Colors.primary};
      opacity: 0.8;
      border-color: white;
    }
    &:focus {
      background-color: ${Colors.primary};
      color: white;
      border-color: white;
    }
  }
}`;

export default CardAverageCPMHolder;