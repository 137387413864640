import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardAdsInfoHolder = styled.div`
  .cardGeneral {
    position: static;
    padding-left: 10px;
    padding-bottom: 10px;
    z-index: 996;
    height: auto;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    border-radius: 8px;
    width: 88%;
    float: right;
    margin: 10px 10px 0px 0px;

    @media only screen and (max-width: 1400px) {
      .impacts-container {
        line-height: 20px;
      }
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .infoDate {
    margin-top: 10px;
    margin-bottom: 1px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .brandClientNameContainer {
    background-color: white;
    border-radius: 8px;
    margin-right: 10px;
   }
   .brandClientName {
    margin: 0;
    color: ${Colors.primary};
    font-weight: 500;
    font-size: 14px;
   }
`;

export default CardAdsInfoHolder;