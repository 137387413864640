import React, { useState, useEffect } from 'react';
import { Popover, Progress } from 'antd';
import Colors from "../themes/Colors";
import { useTranslation } from 'react-i18next';
import { calculateValueByCpm, hasAccessRole} from "../services/utils";
import { formatNumber } from "../services/utils";

const calculateShows = (reportSingleData, relationId) => {
  const foundElement = reportSingleData &&
      reportSingleData.report &&
      reportSingleData.report.find(element => element.content_display.id === relationId);
  return foundElement ? foundElement.shows : 0;
};

const ImpressionsDetailsPopover = ({
    reportSingleData,
    customContents,
    display,
    relation,
    editCampaignActive,
    currentCampaign,
    cpmDisplay }) => {

  const [consumed, setConsumed] = useState(0);
  const [toConsumed, setToConsumed] = useState(0);
  const [totalMaterial, setTotalMaterial] = useState(0);
  const [totalSubsidizePromisedShows, setTotalSubsidizePromisedShows] = useState(0);
  const [totalPromisedShows, setTotalPromisedShows] = useState(0);
  const [successPercentForRelations, setSuccessPercentForRelations] = useState(0);

  const displayId = display.id;
  const relationId = relation.relationId;

  const {t}  = useTranslation();

  const defineInitialValues = () => {
    let totalSubsidizedPromisedShows = 0;
    let totalPromisedShows = 0;
    let displayId = display.id

    if (editCampaignActive && currentCampaign.payment_status === 'approved') {
      customContents.forEach(customContent => {
        if (customContent.displayId === displayId) {
          totalPromisedShows += customContent.promised_shows - (customContent.promised_shows_subsidized || 0);
          totalSubsidizedPromisedShows += customContent.promised_shows_subsidized || 0;
        }
      });
    } else {
      customContents.forEach(customContent => {
          if (customContent.displayId === displayId) {
            totalPromisedShows += customContent.promised_shows;
            totalSubsidizedPromisedShows += customContent.promised_shows_subsidized || 0;
          }
      });
    }
    setTotalSubsidizePromisedShows(totalSubsidizedPromisedShows);
    setTotalPromisedShows(totalPromisedShows);
  };

  const convertToInversion = (impresions) => {
    if (impresions === 0) {
      return 0;
    }
    let formatValue = formatNumber(Math.floor(parseFloat(calculateValueByCpm(cpmDisplay, impresions, "calculate_price")) * 100) / 100 || 0);
    formatValue = `${display.price_currency} ${formatValue} ( ${impresions} ${t('Impressions')} )`;
    return formatValue;
  }

  const progressPercent = Math.round(((relation.promised_shows + relation.promised_shows_subsidized) * 100 /
    (totalPromisedShows + totalSubsidizePromisedShows) * 100) / 100);

  useEffect(() => {
    defineInitialValues();
  }, [reportSingleData, displayId, relation, customContents]);

  useEffect(() => {
    const shows = calculateShows(reportSingleData, relationId);
    const successPercent = Math.round((consumed * 100 / (totalPromisedShows + totalSubsidizePromisedShows) * 100) / 100);
    setSuccessPercentForRelations(successPercent);
    setConsumed(shows);
    setToConsumed((relation.promised_shows + relation.promised_shows_subsidized) - shows);
    setTotalMaterial(shows + (relation.promised_shows - shows));
  }, [totalPromisedShows, customContents]);

  return (
    <span style={{ display: "contents" }}>
      <Popover
        placement="left"
        content={
          <div>
            {t('Total on display')}{" "}
            <span style={{ color: Colors.primary }}>
              {convertToInversion(totalPromisedShows)}
            </span><br />
            {t('Total on version')}{" "}
            <span style={{ color: Colors.primary }}>
              {convertToInversion(totalMaterial)}
            </span><br />
            {/* Consumed of relations */}
            {t('Consumed')}{" "}
            <span style={{ color: Colors.primary }}>
              { convertToInversion(consumed) }
            </span><br />
            {t('Remaining')}{" "}
            <span style={{ color: Colors.primary }}>
              {convertToInversion(toConsumed)}
            </span>
            <br />
            {totalSubsidizePromisedShows > 0 ? (
              <>
                {t('Total material subsidized')}{" "}
                <span style={{ color: Colors.primary }}>
                  {convertToInversion(relation.promised_shows_subsidized)}
                </span><br />
                {t('Total subsidize')}{" "}
                <span style={{ color: Colors.primary }}>
                  {convertToInversion(totalSubsidizePromisedShows)}
                </span><br />
              </>
            ) : null}
          </div>
        }
        title={t('Investment detail')}
      >
      <div className="custom-progress">
          <Progress
            percent={progressPercent}
            successPercent={successPercentForRelations}
            type="circle"
            width={40}
          />
      </div>
      </Popover>
      {/* <span style={{ marginLefth: 15 }}>{progressPercent}%</span> */}
    </span>
  );
};

export default ImpressionsDetailsPopover;