import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Icon, Row, Tooltip, Typography, Button, Col} from "antd";
import CartInitialBudgetStyleHolder from "./Styles/cartInitialBudgetStyle";
import NumberFormat from "./NumberFormat";
import { getTotalPice } from "../services/display";


const CartInitialBudgetComponent = ({
  stepsCurrent,
  updateByAmountBudget,
  amountBudget,
  isPaymentStatusApproved,
  currency,
  cart,
  programmatic,
  invoiceIssuingCountry,
  date_from,
  date_to}) => {

  const { t } = useTranslation();
    const [amountBudgetTraditional, setAmountBudgetTraditional] = useState(0)
    useEffect(() => {
      if(programmatic === 'programmatic') return
      setAmountBudgetTraditional(getTotalPice(cart, date_from, date_to))
    },[date_from, date_to, cart])


  return (
    <CartInitialBudgetStyleHolder>
      <div className="cartBudget">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            { programmatic === "programmatic" ? (
              <>
                <span style={{fontSize: '12px', marginLeft: '5px' }}>{t("Campaign budget")}</span>
              </>
            ) : (
              <span style={{fontSize: '12px'}}>{t("Total price")}</span>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className='content-info' style={{ marginTop: '3px', marginLeft: '5px' }}>
                <NumberFormat
                  value={ programmatic === 'programmatic' ?
                      Number(amountBudget).toFixed(2)
                      : amountBudgetTraditional
                  }
                  currency= { cart[0] && cart[0].id ? cart[0].price_currency : currency }
                />
                <span style={{fontSize: '10px'}}> + imp</span>
              </div>
          </Col>
        </Row>
        {(programmatic === "programmatic" && stepsCurrent !== 0 ) && (
          <Row style={{textAlign: "center"}}>
            {((stepsCurrent !== 0) && !isPaymentStatusApproved ) && (
                <Tooltip
                  className="appear-animation-without-movement"
                  placement="right"
                  title={t('For each screen it will equalize the investment and recalculate the spots (changes you have made to spots for each ad or screen will be erased)')}
                >
                  <Button
                    className='groupFilterButton'
                    onClick={() => updateByAmountBudget(amountBudget)}
                  >
                    <span className='balanceButtonText'>
                      {t("Balance $")}
                    </span>
                  </Button>
                </Tooltip>
              )}
          </Row>
        )}
      </div>
    </CartInitialBudgetStyleHolder>
  );
}

export default CartInitialBudgetComponent;
