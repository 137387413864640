import React, { Component, Fragment } from "react";
import {
  SelectionFiltersHolder,
  CollapseHolder,
} from "./Styles/selectionFiltersStyle";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
  Icon,
  Collapse,
  Row,
  Col,
  Input,
  Modal,
  DatePicker,
  Divider,
  Select,
  Button,
  Form,
  Badge,
  Tooltip
} from "antd";
import Colors from "../themes/Colors";
import RefinateFiltersForm from "../containers/RefinateFiltersContainer";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole, getMinimunByCurrency} from "../services/utils";
import { withTranslation } from "react-i18next";
import { ProgrammaticSvg } from "../images/customIcons/ProgrammaticSvg";
import { TraditionalSvg } from "../images/customIcons/TraditionalSvg";
import { updatePriceCartByDiscountDisplay} from "../services/display";
import countriesEnum  from "../constants/CountriesEnum";
import NumberFormat from "./NumberFormat";
import VerifiedAgencyInfoModal from "./VerifiedAgencyInfoModal";

const { Option } = Select;

const Panel = Collapse.Panel;

const { RangePicker } = DatePicker;

const ProgramaticIcon = (props) => (
  <Icon component={ProgrammaticSvg} {...props} />
);

const TraditionalIcon = (props) => (
  <Icon component={TraditionalSvg} {...props} />
);

class SelectionFiltersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFiltersModal: false,
      showPanel: false,
      errors:{},
      verifiedAgencyError: null
    };
    this.props.setCountries([]);
  }

  handleShowFiltersModal = () => {
    this.setState((prevState) => ({
      showFiltersModal: !prevState.showFiltersModal,
    }));
  };

  toggleShowPanel = () => {
    this.setState((prevState) => ({
      showPanel: !prevState.showPanel,
    }));
  };

  handleShowFiltersCancel = () => {
    this.setState({
      showFiltersModal: false,
    });
  };

  async handlePriceChange(price) {
    await this.setState({ price });
    this.props.setPrice(price);
  }

   // Limit traditional campaigns to a week for non verified agencies
   validateDates = (dates) => {
    const {user, programmatic, t} = this.props;
    const isUnverifiedAgency = user && user.company && user.company.type === 'agency' && !user.company.is_verified;
    const validDates = dates && dates.length > 1;
    const isSuperUser = user && user.roles && (user.roles.includes(UserRoleEnum.superAdmin) || user.roles.includes(UserRoleEnum.superModeratorAgency));
    if (isUnverifiedAgency && validDates && programmatic === 'traditional' && !isSuperUser) {
      const diff = dates[1].diff(dates[0], 'days');
      if (diff > 7) {
        this.setState({ verifiedAgencyError: t("The maximun duration of a traditional campaign for non-verfied agencies is limited to a week.") });
        this.showVerifiedAgencyModal();
        return false;
      }
    }
    return true;
  }

  handleDateChange = (dateStrings) => {
    if(!this.validateDates(dateStrings)) return;

    let startDate = dateStrings[0];
    let endDate = dateStrings[1];

    //condition when press "x" to delete
    if (!startDate && !endDate) {
      const today = new Date();
      startDate = moment(today).add(-1, 'd');
      endDate = moment(today).add(30, 'd');
    }

    const { setDates } = this.props;
    startDate = moment(startDate);
    endDate = moment(endDate);
    setDates(
      startDate && startDate.format("YYYY-MM-DD"),
      endDate && endDate.format("YYYY-MM-DD")
    );
  };

  formatDate = (date) =>{
    if (date && date.includes("-")) return moment(date);
    return moment();
  }


  showVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: true });
  };

  hideVerifiedAgencyModal = () => {
    this.setState({ isVerifiedAgencyModalVisible: false });
  };

  async handleShowsPerDayChange(showsPerDay) {
    await this.setState({ showsPerDay });
    this.props.setShowsPerDayMin(showsPerDay);
  }

  componentDidMount() {
    this.props.getConversionRates();
  }

  componentDidUpdate(nextProps){
    if(this.props.programmatic != nextProps.programmatic || this.props.currency != nextProps.currency){
      this.checkMinimunBudget(this.props.amountBudget)
    }
    if(this.props.countries !== nextProps.countries) {
      this.props.refreshDisplays();
    }

    if (this.props.programmatic === "traditional" && (this.props.date_to != nextProps.date_to || this.props.date_from != nextProps.date_from)) { updatePriceCartByDiscountDisplay(this.props.displays, this.props.date_from, this.props.date_to, this.props.updatePriceCartByDiscount) }
    if (!this.validateDates([moment(this.props.date_from), moment(this.props.date_to)])) {
      this.handleDateChange([moment(this.props.date_from), moment(this.props.date_from).add(7, 'days')])
    }

    if(this.props.user != nextProps.user){
      this.props.setBudget(this.validateBudget(this.props.amountBudget));
    }

  }

  renderIdentificationClients = () => {
    if (this.props.clients && this.props.clients.length > 0) {
      return this.props.clients.map((client) => {
        return (
          <Option key={client.id} value={client.id}>
            {client.name}
          </Option>
        );
      });
    }
  };

  renderIdentificationBrands = () => {
    if (this.props.brands && this.props.brands.length > 0) {
      return this.props.brands.map((brand) => {
        return (
          <Option key={brand.id} value={brand.id}>
            {brand.name}
          </Option>
        );
      });
    }
  };

  renderIdentificationCountries = () => {
    return countriesEnum.map((country) => {
      return (
        <Option key={country.key} value={country.key}>
          {country.name}
        </Option>
      );
    });
  };

  renderIdentificationDsp = () => {
    let listDsp =  [...this.props.dsps];
    listDsp.unshift({ id: null, name: 'Latinad', exchange: 'latinad' });

    return listDsp.map(dsps => {
      return (
        <Select.Option default={dsps.id == null} key={dsps.id} value={dsps.id}>
          {dsps.name} <span style= {{color:"rgb(135 135 135)"}}>{dsps.exchange}</span>
        </Select.Option>
      );
    });
  };

  changeClient = (clientSelect) => {
    const client = this.props.clients.find(
      (client) => client.id === clientSelect.key
    );
    this.props.setClient(client);
    this.props.setBrands(client.brands);
  };
  changeBrand = (brandSelect) => {
    this.props.setBrand(
      this.props.brands.find((brand) => brand.id === brandSelect.key)
    );
  };

  changeDsp = (dspSelect) => {
    this.props.setDsp(this.props.dsps.find(dsp => dsp.id === dspSelect.key))
  }

  changeCountries = (countriesSelected) => {
    this.props.setCountries(
      countriesSelected
    );
  };

  // Limit programmatic campaign to 5000USD budget for non verified agencies
  validateBudget = (budget) => {
    const {user, programmatic, currency, conversionRates, t} = this.props;
    const isUnverifiedAgency = user && user.company && user.company.type === 'agency' && !user.company.is_verified;
    const conversionRate = conversionRates.find(rate => rate.currency === currency);
    const maxBudget = 5000 * conversionRate.rate;
    const overBudget = budget > maxBudget;
    const isSuperUser = user && user.roles && (user.roles.includes(UserRoleEnum.superAdmin) || user.roles.includes(UserRoleEnum.superModeratorAgency));
    if (isUnverifiedAgency && programmatic === 'programmatic' && overBudget && !isSuperUser) {
        this.setState({ verifiedAgencyError: t('The maximum budget for non verified agencies is 5000 USD a month') });
        this.showVerifiedAgencyModal();
        return Math.floor(maxBudget);
    }
    return budget;
  }

  handleAmountChange(e) {
    let rawValue = e.target.value;
    let numericValue = rawValue === '' ? null : Number(rawValue);
    const MAX_VALUE = 99999999.99;
    if (numericValue !== null) {
      numericValue = Math.floor(numericValue * 100) / 100;
      if (numericValue > MAX_VALUE) {
        numericValue = MAX_VALUE;
      }
    }
    const validatedBudget = this.validateBudget(numericValue);
    this.checkMinimunBudget(validatedBudget);
    this.props.setBudget(validatedBudget);
  }

  checkMinimunBudget(num){
    const errors = {};
    const {t, currency, programmatic} = this.props
    let min = getMinimunByCurrency(currency)
    if(num < min && programmatic === "programmatic"){
       errors["budget"] = t('The minimum purchase price is') + " "+ currency + " "+ min
    }
    this.setState({ errors });
  }

  searchCountry = (input, option) => (
    [
        option.key.toLowerCase(), // filter by ISO code
        option.props.children.toLowerCase(), // filter by name
        option.props.children.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "") // filter by name w/o diacritics
    ].some(f => f.includes(input.toLowerCase()))
  );

  render() {
    const {
      t,
      user,
      date_from,
      date_to,
      size_type,
      location_type,
      location_sub_type,
      programmatic,
      currency,
      amountBudget,
      countries,
      isCircuit,
      dsp,
      editCampaignActive,
      isSuperUser
    } = this.props;

    let isQuote = window.location.href.includes("external_dsp_id");
    const { errors } = this.state;

    // check when initialize view
    if(amountBudget < getMinimunByCurrency(currency) && !errors["budget"] && programmatic === "programmatic"){
       this.checkMinimunBudget(amountBudget)
    }
    let isAgency = false;

    if (
      user &&
      hasAccessRole(user.roles, [
        UserRoleEnum.agencyMember,
        UserRoleEnum.companyMember,
      ])
    ) {
      isAgency = true;
    }

    const customPanelStyle = {
      background: Colors.snow,
      borderRadius: "16px",
      border: 0,
      overflow: "hidden",
    };

    function disabledDate(current) {
      // Can not select days before today
      return current && current < moment().subtract(1,'days').endOf('day')
    }

    const alertBadge = () => (
      <Badge
        count={size_type.length + location_type.length + location_sub_type.length + (countries.length > 0) + (isCircuit ? 1 : 0)}
        style={{
          backgroundColor: Colors.atention,
          top:0
        }}
      />
    );

    return (
      <CollapseHolder>
        <Collapse
          activeKey={+this.state.showPanel}
          bordered={false}
          onChange={this.toggleShowPanel}
          style={{
            borderRadius: "16px",
            boxShadow: "0px 1px 3px rgb(39 140 242 / 25%)",
            marginBottom: "3px",
          }}
        >
          <Panel
            header={t("Selection filters")}
            key="1"
            style={customPanelStyle}
            extra={!this.state.showPanel && alertBadge()}
          >
            <SelectionFiltersHolder>
              <Row gutter={20} type="flex">
                <Fragment>
                  <Row type="flex" align="middle" className="typePurchase">
                    <Button
                      size="large"
                      type="primary"
                      ghost
                      value="traditional"
                      style={{
                        paddingRight: "19px",
                        paddingLeft: "19px",
                      }}
                      className={
                        this.props.programmatic === "traditional"
                          ? "purchaseTypeButtonSelected"
                          : "purchaseTypeButton"
                      }
                      onClick={async (e) => {
                        await this.props.setProgrammatic("traditional");
                        this.props.refreshDisplays("traditional");
                        this.props.cleanAudience();
                        this.props.removeDisplaysCart();
                        if(!isSuperUser) this.props.setDsp(null);
                      }}
                    >
                      <TraditionalIcon />
                      {t("Traditional")}
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      ghost
                      className={
                        this.props.programmatic === "programmatic"
                          ? "purchaseTypeButtonSelected"
                          : "purchaseTypeButton"
                      }
                      value="programmatic"
                      onClick={async (e) => {
                        await this.props.setProgrammatic("programmatic");
                        this.props.refreshDisplays("programmatic");
                        this.props.cleanAudience();
                        this.props.removeDisplaysCart();
                      }}
                    >
                      <ProgramaticIcon />
                      {t("Programmatic")}
                    </Button>
                  </Row>
                  <Divider style={{ margin: "20px 0px" }} />
                  <Row type="flex" align="middle" className="noWrap longInput">
                    <span style={{ paddingRight: "5px" }}>{t("Countries")}</span>
                    <span className="inputSelect">
                      <Select
                        allowClear
                        placeholder={t("Select")}
                        style={{ width: "100%" }}
                        mode="multiple"
                        onChange={this.changeCountries.bind(this)}
                        value={[...this.props.countries]}
                        filterOption={this.searchCountry.bind(this)}
                      >
                        {this.renderIdentificationCountries()}
                      </Select>
                    </span>
                  </Row>
                  {isAgency ? (
                    <Fragment>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row type="flex" align="middle" className="noWrap">
                          <span className="inputWidth">{t("Client")}</span>
                          <Select
                            className="inputSelect"
                            labelInValue
                            onChange={this.changeClient.bind(this)}
                            loading={this.props.loadingClients}
                            value={{
                              key: this.props.client && this.props.client.id
                                ? parseInt(this.props.client.id)
                                : t("Select"),
                            }}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.renderIdentificationClients()}
                          </Select>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row type="flex" align="middle" className="noWrap">
                          <span className="inputWidth">{t("Mark")}</span>
                          <Select
                            className="inputSelect"
                            labelInValue
                            onChange={this.changeBrand.bind(this)}
                            loading={this.props.loadingBrands}
                            disabled={this.props.loadingBrands}
                            value={{
                              key: this.props.brand && this.props.brand.id
                                ? parseInt(this.props.brand.id)
                                : t("Select"),
                            }}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.renderIdentificationBrands()}
                          </Select>
                        </Row>
                      </Col>
                    </Fragment>
                  ):null}
                  <Row type="flex" align="middle" className="noWrap longInputTwo">
                    <span
                      style={{paddingBottom: "25px", marginRight: "5px"}}>
                      {t("Budget")}
                    </span>
                    <Form.Item
                        validateStatus={!!errors["budget"] && "error" ? "error" : null}
                        help={errors["budget"]}
                    >
                      <Input
                        type="number"
                        prefix="&nbsp;&nbsp;$"
                        min={10}
                        max={99999999.99}
                        placeholder="0"
                        className="inputBudget"
                        disabled={this.props.programmatic === "traditional"}
                        value={this.props.amountBudget}
                        onChange={this.handleAmountChange.bind(this)}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Row>
                  { user && user.company && user.company.enable_external_dsp_selector ?
                    <Row type="flex" align="middle" className="noWrap longInputTwo">
                        <Tooltip
                          placement="right"
                          style={{marginBottom: "25px"}}
                          title={
                          <span>{t("info_dsp")}</span>
                          }>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" , marginBottom: "25px"}}>
                            DSPs
                            <Icon
                              type="info-circle"
                              style={{ marginLeft: "10px", color: !dsp || (dsp && dsp.id == null) ? "#aaaaaad1" : "#faad14" }}
                              theme="filled"
                            />
                          </div>
                        </Tooltip>
                      <Form.Item>
                        <Select
                          disabled={(this.props.programmatic === "traditional" && !isSuperUser) || editCampaignActive || isQuote}
                          className="inputSelect"
                          labelInValue
                          onChange={this.changeDsp.bind(this)}
                          loading={this.props.loadingDsp}
                          value={{
                            key: dsp && dsp.id
                              ? parseInt(dsp.id)
                              : 'Latinad',
                          }}
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children && option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.renderIdentificationDsp()}
                        </Select>
                      </Form.Item>
                    </Row>: null
                  }
                </Fragment>
                <Row type="flex" align="middle" className="noWrap longInput">
                  <span>{t("Dates")}</span>
                  <span className="inputSelect">
                    <RangePicker
                      style={{ width: "100%" }}
                      value={[this.formatDate(date_from) ,this.formatDate(date_to)]}
                      onChange={this.handleDateChange}
                      disabledDate={disabledDate}
                    />
                  </span>
                </Row>
              </Row>

              <Row
                align="middle"
                type="flex"
                justify="end"
                style={{ width: "100%" }}
              >
                <Badge
                  count={size_type.length + location_type.length + location_sub_type.length + (countries.length > 0) + (isCircuit ? 1 : 0)}
                  style={{
                    backgroundColor: Colors.atention,
                    left: "46px",
                    bottom: "12px",
                  }}
                >
                  <Button
                    type="primary"
                    className="buttonPrimary"
                    onClick={this.handleShowFiltersModal}
                  >
                    {t("More")}...
                  </Button>
                </Badge>
              </Row>
              <Modal // FILTERS MODAL
                title={t("Refinate your search")}
                visible={this.state.showFiltersModal}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={this.handleShowFiltersModal}
                onCancel={this.handleShowFiltersCancel}
                width={window.innerWidth > 768 ? "73%" : "80%"}
              >
                <RefinateFiltersForm
                  t={t}
                  changeClient={this.changeClient}
                  changeBrand={this.changeBrand}
                  changeCountries={this.changeCountries}
                  renderIdentificationClients={this.renderIdentificationClients}
                  renderIdentificationBrands={this.renderIdentificationBrands}
                  renderIdentificationCountries={this.renderIdentificationCountries}

                  dates={[date_from, date_to]}
                />
              </Modal>
            </SelectionFiltersHolder>
          </Panel>
        </Collapse>
        <VerifiedAgencyInfoModal isVisible={this.state.isVerifiedAgencyModalVisible} hide={this.hideVerifiedAgencyModal} message={this.state.verifiedAgencyError}></VerifiedAgencyInfoModal>
      </CollapseHolder>
    );
  }
}
export default withTranslation()(SelectionFiltersComponent);
