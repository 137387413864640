import React, { useMemo, useState } from 'react';
import { Tooltip, Button, Icon, Modal} from 'antd';
import { useTranslation } from 'react-i18next';
import { SeparateNumberFormat, calculateValueByCpm } from "../services/utils";
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import { getAmountAudienceDisplay, getAmountAudience } from "../services/display";
import AudienceImpactsHolder from './Styles/audienceImpactsStyle';
import NumberFormat from './NumberFormat';
import Dotdotdot from 'react-dotdotdot';

const TotalInvestmentComponent = ({ displays, type = 'default', audience , relationId, editCampaignActive, currentCampaign, user, audienceHoursOfDay, audienceDaysOfWeek, getAudienceHoursOfDayRequest, getAudienceDaysOfWeekRequest, currency, display, customContents, cpmDisplay }) => {

    const [visibleAudienceView, setVisibleAudienceView] = useState(false);
    const { t } = useTranslation();

    const [totalAudience, totalImpacts] = useMemo(() => {
        let totalAudience = 0;
        let totalImpacts = 0;
    const displayList = Array.isArray(displays) ? displays : [displays];
    if (type === 'relation') {
        totalAudience = getAmountAudience(audience, relationId) || 0;
        totalImpacts = getAmountAudience(audience, relationId, true) || 0;
    } else {
        displayList.forEach(display => {
            totalAudience += getAmountAudienceDisplay(audience, display.id) || 0;
            totalImpacts += getAmountAudienceDisplay(audience, display.id, true) || 0;
        });
    }

        return [totalAudience, totalImpacts];
    }, [audience, displays]);

  if (totalAudience <= 0) {
    return null;
  }

  const getTooltipImpacts = () => {
    return t("Real-time screen impacts, shows the number of people who will see your spot or group of spots based on camera measurement data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impact it will generate.");
  }
  const showAudienceModal = () => {
    setVisibleAudienceView(!visibleAudienceView);
  };

  const totalInvestment = useMemo(() => {
    const relations = customContents.filter(item => item.displayId === display.id);
    return relations.reduce((total, relation) => {
        const spotValue = Math.floor(parseFloat(calculateValueByCpm(cpmDisplay, relation.promised_shows, "calculate_price")) * 100) / 100 || 0;
        return total + spotValue;
    }, 0);
}, [customContents, display, cpmDisplay]);

  return (
    <AudienceImpactsHolder>
      <div
        className={ type === 'relation' ? "col-displays content-audience-relation" :"col-displays content-audience" }
      >
        <div>
          <Tooltip placement="bottomLeft" title={t('Total investment for this screen')}>
            <div className="screenCardAudit" size='small' style={{fontSize: '12px'}}>
              <Dotdotdot clamp={1}>
                <span>{currency} {SeparateNumberFormat(totalInvestment)}</span>
              </Dotdotdot>
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="bottomLeft" title={t('CPM')}>
            <div className="screenCardAudit" size='small' style={{fontSize: '12px'}}>
                <NumberFormat
                    value ={display.cpmi}
                    currency={currency}
                /> {t("CPM")}
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="bottomLeft" title={getTooltipImpacts()}>
            <Button className="screenCardAudit" size='small' onClick={() => showAudienceModal()}>
              <Icon type="eye" theme="outlined"/>
              <span style={{fontSize: '12px'}}>{SeparateNumberFormat(totalImpacts)}</span>
            </Button>
          </Tooltip>
        </div>
        {type === 'display' && (
          <Modal
            title={t("Audience")}
            visible={visibleAudienceView}
            onOk={() => showAudienceModal()}
            onCancel={() =>showAudienceModal()}
            width={1000}
          >
          <AudienceSummaryComponentGeneral
            t={t}
            display={displays}
            user={user}
            campaign={editCampaignActive ? currentCampaign : null}
            audienceHoursOfDay = {audienceHoursOfDay}
            audienceDaysOfWeek = {audienceDaysOfWeek}
            getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
            getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest}
            />
          </Modal>
        )}
      </div>
    </AudienceImpactsHolder>
  );
};

export default TotalInvestmentComponent;