import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardGeneralHolder = styled.div`
  .cardGeneral {
    padding-left: 10px;
    padding-bottom: 20px;
    position: static;
    z-index: 996;
    height: auto;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    width: 88%;
    margin: 10px 10px 0px 10px;

    @media only screen and (max-width: 1400px) {
      .impacts-container {
        line-height: 20px;
      }
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .cartAudienceHidden {
    position: fixed;
    z-index: 2;
    top: calc(50% - 25px);
    right: -150px;
    height: 45px;
    width: 150px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    @media only screen and (min-width: 768px) {
      top: calc(85px + 127px + 140px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(85px + 77px + 120px);
    }
  }
  .text-title {
    text-align: left;
    font-size: 22px;
    opacity: 0px;
    margin-top: 5px;
  }
  .infoDate {
    margin-top: 5px;
    margin-bottom: 1px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
    width: 100%;
    
    @media only screen and (min-width: 576px) {
      font-size: 12px;
    }
  }
  .ant-calendar-picker {
    width: 80%;
  }
  .contentDate {
    text-align: justify;
  }
  .content-info {
    width: 55px;
    height: 32px;
    padding: 5px 6px 5px 6px;
    border-radius: 4px;
    background-color: #D7EAFD;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #3996F3;
  }
  .ant-input[disabled] {
    color: #00000099 !important;
  }
  .datePicker {
    width: 100%;
    padding-right: 10px;
  }
  `;

export default CardGeneralHolder;